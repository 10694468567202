import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire/compat';
import {localStorageSync} from "ngrx-store-localstorage";

import { environment } from 'environments/environment';
import { AppComponent } from './app.component';
import { AuthComponent } from './pages/auth/auth.component';
import { AppRoutingModule } from './app-routing.module';
import { MdbSidenavModule } from "mdb-angular-ui-kit/sidenav";
import { MdbNotificationModule } from "mdb-angular-ui-kit/notification";
import { MdbDropdownModule } from "mdb-angular-ui-kit/dropdown";
import { MdbRippleModule } from "mdb-angular-ui-kit/ripple";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { getWindow } from 'ssr-window';

// Service
import { NotificationModule } from './services';

// Store
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
const StoreDevtools = !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50, connectInZone: true}) : [];
import { reducers, effects } from './store'
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';

import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import {NgxSpinnerModule} from "ngx-spinner";
import {CartState} from "@app/store/cart";
import {HttpClient, HttpClientModule, provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {getRemoteConfig, provideRemoteConfig} from "@angular/fire/remote-config";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {provideServerRendering} from "@angular/platform-server";

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [{cart: { deserialize: CartState.deserialize } }, 'merchant', 'product', 'quotation', 'bill'], rehydrate: true,
    storage: getWindow().localStorage,
    checkStorageAvailability: true
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MdbSidenavModule,
    MdbNotificationModule,
    MdbRippleModule,
    MdbDropdownModule,
    AngularFireModule.initializeApp(environment.firebase.config),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot(effects),
    StoreDevtools,
    NotificationModule.forRoot(),
    AngularFireFunctionsModule,
    NgxSpinnerModule,
    NgxGoogleAnalyticsModule.forRoot('MEASUREMENT-ID'),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {provide: REGION, useValue: environment.region},
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    provideClientHydration(),
    provideFirebaseApp(() => initializeApp(environment.firebase.config)),
    provideFirestore(() => getFirestore()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideHttpClient(withFetch(),withInterceptorsFromDi()),
    provideServerRendering(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
