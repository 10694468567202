<div class="view deep-purple darken-4">
  <div class="mask d-flex justify-content-center align-items-center">
    <div class="container text-white">
      <div class="row">
        <div class="col-md-6 text-center text-md-left wow fadeInLeft" data-wow-delay="0.3s">
          <div class="d-flex justify-content-center mt-6">
            <img src="assets/images/store-bloc-logo-white.png" class="img-fluid" alt="Responsive image" />
          </div>
          <div class="d-flex justify-content-center mt-1 h2-responsive">
            <strong>Store Block</strong>
          </div>

          <div class="text-center mt-md-3">
            <div class="h5-responsive">Welcome to our customer!</div>
          </div>
          <br />

          <div>
            <div class="h6-responsive d-flex justify-content-center mt-3">
              <div class="text-center">
                Welcome back to StoreBloc! We're thrilled to see you again.
                Dive right into our curated collections tailored just for you.
                Let's make your shopping experience unforgettable today. 🛍️💖
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-xl-5 mb-4">
          <router-outlet></router-outlet>
        </div>

      </div>
    </div>
  </div>
</div>
