import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/static/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: '404',
    loadChildren: () => import('./pages/static/pages/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: 'static',
    loadChildren: () => import('./pages/static/static.module').then(m => m.StaticModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import('./pages/static/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'enabled',
    }
  )],
  exports: [RouterModule]
})


export class AppRoutingModule { }
