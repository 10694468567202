import { ActionReducerMap } from '@ngrx/store';

import * as fromUser from './user';
import * as fromProduct from './product';
import * as fromProductPaging from './product-paging';
import * as fromQuotation from './quotation';
import * as fromQuotationPaging from './quotation-paging';
import * as fromMerchant from './merchant';
import * as fromCart from './cart';
import * as fromInquiries from './inquiries';
import * as fromPayment from './payment';
import * as fromPaymentDetails from './payment-details';
import * as fromOrders from './orders';
import * as fromBill from './bill';
import * as fromLivepage from './livepages';
import * as fromPosts from './posts';
import * as fromPostsPaging from './posts-paging';
import * as fromTags from './tags';

export interface State {
    user: fromUser.UserState;
    product: fromProduct.ProductState;
    productPaging: fromProductPaging.ProductPagingState;
    quotation: fromQuotation.QuotationState;
    quotationPaging: fromQuotationPaging.QuotationPagingState;
    merchant: fromMerchant.MerchantState;
    cart: fromCart.CartState;
    inquiries: fromInquiries.InquiriesState;
    payment: fromPayment.PaymentState;
    paymentDetails: fromPaymentDetails.PaymentDetailsState;
    orders: fromOrders.OrderState;
    bill: fromBill.BillState;
    livepage: fromLivepage.LivepageState;
    post: fromPosts.PostState;
    postPaging: fromPostsPaging.PostPagingState;
    tags: fromTags.TagState;
}

export const reducers: ActionReducerMap<State, any> = {
    user: fromUser.reducer,
    product: fromProduct.reducer,
    productPaging: fromProductPaging.reducer,
    quotation: fromQuotation.reducer,
    quotationPaging: fromQuotationPaging.reducer,
    merchant: fromMerchant.reducer,
    cart: fromCart.reducer,
    inquiries: fromInquiries.reducer,
    payment: fromPayment.reducer,
    paymentDetails: fromPaymentDetails.reducer,
    orders: fromOrders.reducer,
    bill: fromBill.reducer,
    livepage: fromLivepage.reducer,
    post: fromPosts.reducer,
    postPaging: fromPostsPaging.reducer,
    tags: fromTags.reducer
};

export const effects = [
    fromUser.UserEffects,
    fromProduct.ProductEffects,
    fromProductPaging.ProductPagingEffects,
    fromQuotation.QuotationEffects,
    fromQuotationPaging.QuotationPagingEffects,
    fromMerchant.MerchantEffects,
    fromCart.CartEffects,
    fromInquiries.InquiriesEffects,
    fromPayment.PaymentEffects,
    fromPaymentDetails.PaymentDetailsEffects,
    fromOrders.OrderEffects,
    fromBill.BillEffects,
    fromLivepage.LivepageEffects,
    fromPosts.PostEffects,
    fromPostsPaging.PostPagingEffects,
    fromTags.TagsEffects
];
